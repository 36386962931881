import React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { Link } from "gatsby";
import { CategoryWithPost, Post } from "app-types/blog";
import { useTranslation } from "react-i18next";
import {
  NextContainer,
  Pagination,
  PostContainer,
  PrevContainer,
} from "./index.style";
import Loop from "components/core/Loop";
import BlogPost from "components/ui/base/BlogPost";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { PageContainer } from "styles/global.style";
import If from "components/core/If";
import PlainHeader from "components/ui/base/PlainHeader";

export interface BlogCategoryProps {
  pageContext: {
    category: CategoryWithPost;
    posts: Post[];
    pagination: {
      limit: number;
      skip: number;
      numberOfPages: number;
      currentPage: number;
      newerPath: string;
      olderPath: string;
    };
  };
  location: Location;
}

const BlogCategory = ({ pageContext, location }: BlogCategoryProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={`All posts of ${pageContext.category.name}`}
        description={pageContext.category.description}
      />

      <PlainHeader
        title={pageContext.category.name}
        description={pageContext.category.description}
        titleMaxWidth={650}
        descriptionMaxWidth={700}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Blog",
            path: "/blog/",
          },
          {
            name: pageContext.category.name,
            path: `/blog/category/${pageContext.category.slug}/`,
          },
        ]}
      />

      <PostContainer>
        <PageContainer>
          <Container>
            <Row>
              <Loop items={pageContext.posts}>
                {(post, idx) => (
                  <Col key={idx} sm={12} md={6}>
                    <BlogPost post={post} />
                  </Col>
                )}
              </Loop>
            </Row>
          </Container>

          <Container>
            <Row>
              <Col>
                <Pagination>
                  <PrevContainer>
                    <If
                      condition={pageContext.pagination.olderPath !== undefined}
                    >
                      <Link to={pageContext.pagination.olderPath}>
                        {t("templates.blog.category.older")}
                      </Link>
                    </If>
                  </PrevContainer>
                  <NextContainer>
                    <If
                      condition={pageContext.pagination.newerPath !== undefined}
                    >
                      <Link to={pageContext.pagination.newerPath}>
                        {t("templates.blog.category.newer")}
                      </Link>
                    </If>
                  </NextContainer>
                </Pagination>
              </Col>
            </Row>
          </Container>
        </PageContainer>
      </PostContainer>
    </GlobalWrapper>
  );
};

export default BlogCategory;
